.input{
    background-color: #1A2029 !important;  
 backdrop-filter: blur(20px) !important;
 border: none !important;
 color: #FFF !important;
 padding: 0 0 0 0.1rem !important;
 font-size: 1.2rem !important;
 /* width: 20rem; */
}
.input:focus {
    border: none !important;
    outline: none !important;
}
.input::placeholder{
    text-align: left !important;
    color: grey !important;
}


.clickable:hover{
    cursor: pointer;
}